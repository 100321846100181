import {WIX_ECOM, MEMBERS_AREA} from '@wix/app-definition-ids';
import {MA_APP_IDS} from '@wix/members-area-integration-kit';

export const ecomAppDefID = WIX_ECOM;
export const currencyConverterAppDefID = '5e762334-899c-4e32-a7ad-b80f03471dfb';
export const membersAppDefId = MEMBERS_AREA;
export const myAccountAppDefId = '14cffd81-5215-0a7f-22f8-074b0e2401fb';
export const addressesAppDefId = '1505b775-e885-eb1b-b665-1e485d9bf90e';
export const myAddressesPageId = 'my_addresses';
export const wishlistPageId = 'wishlist';
export const wishlistAppDefId = '969262e4-c158-4692-8193-a5f335524bff';

export const enum Events {
  manageStores = 'manageStores',
  deletePage = 'deletePage',
  deleteApp = 'deleteApp',
  addShopPage = 'addShopPage',
  appActionClicked = 'appActionClicked',
  dashboardClosed = 'appVisitedInDashboard',
}

export const enum ArticleId {
  stores = '23862857-e4f2-4205-87da-592e3cad64de',
  storesPages = 'b60c6e7b-2b4f-452e-91e3-6708f520ce44',
  storesPagesForCategorySite = '3ca9feee-de5c-494c-a579-15c2938a9390',
}

export const enum Action {
  openDashboard = 'openDashboard',
  openManageProducts = 'openManageProducts',
  openAddPanel = 'openAddPanel',
  openPagesPanel = 'openPagesPanel',
  findProductsToSell = 'findProductsToSell',
  addRelatedApps = 'addRelatedApps',
}

export const tpaApplicationId = 15;

export const EDITOR_SCRIPT_DSN = 'https://f6d8a78a4e9c42588fef2ae550dab44a@o37417.ingest.sentry.io/5223158';

export const ECOM_MEMBERS_AREA_DEFAULT_PAGES = [MA_APP_IDS.MY_ORDERS, MA_APP_IDS.MY_ADDRESSES, MA_APP_IDS.MY_WALLET];

export const enum SPECS {
  InstallNewStoresUponStoresInstallation = 'specs.stores.InstallNewStoresUponStoresInstallation',
  InstallBlocksProductPage = 'specs.stores.InstallBlocksProductPage',
  GalleryNewGFPP = 'specs.stores.GridGalleryNewGFPP',
  ConfigureSlotsInEditorSDK = 'specs.stores.ConfigureSlotsInEditorSDK',
  WishlistInstallationForSlots = 'specs.stores.WishlistInstallationForSlots',
  InstallCategoryPage = 'specs.stores.InstallCategoryPage',
  EnableCategoriesPageInEditorX = 'specs.stores.EnableCategoriesPageInEditorX',
}

export const enum DataStorageKey {
  MySubscriptionsAlreadyInstalled = 'MySubscriptionsAlreadyInstalled',
}

export const dataStorageConfig = {
  [DataStorageKey.MySubscriptionsAlreadyInstalled]: {
    defaultValue: false,
  },
};
